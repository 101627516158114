$padding: 6rem;

.QuizBox {
  display: flex;
  position: absolute;
  left: calc(50% - #{$padding});
  top: calc(50% - #{$padding});
  padding: $padding;

  &.is-open {
    z-index: 100;
  }

  .label {
    display: flex;

    .HoverPoint {
      transform: translateY(-30px);
    }
  }

  &.right {
    .label {
      flex-direction: row-reverse;
    }
  }
}


