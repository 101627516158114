// colors
$clrHighlight: #7EE2D1 !default;
$clrDarkGrey: #343741 !default;
$clrGray: #3E414C !default;
;

$backgroundTransparentDark: rgba(0, 0, 0, 0.6) !default;
$backgroundTransparentLight: rgba(255, 255, 255, 0.2) !default;
$backgroundFilter: #0002 !default;

// sizes
$lineThin: 1px !default;
$lineThick: 2px !default;

// fonts
$fontSmall: 14px !default;
$fontMedium: 16px !default;
$fontLarge: 18px !default;

// values
$defaultOpacity: 0.4 !default;
