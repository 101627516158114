.ScrollIndicator {
  display: flex;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;

  &.show {
    animation: 3s blink ease-in-out infinite;
  }

  &.hide {
    animation: unset;
  }

  svg {
    height: 60px;
  }

  h4 {
    margin-top: 0.4rem;
    font-size: 0.7rem;
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
