.PopupOverlay {
  position: relative;
  max-width: 800px;
  background-color: #343741;
  text-align: left;
  padding: 3rem 2rem 1.5rem 3rem;

  span {
    opacity: $defaultOpacity;
  }

  .RoundButton {
    margin: 0 0 0.5rem 0.3rem;
  }

  .box-left {
    margin-right: 1rem;
    width: 400px;

    .header-container {
      max-width: 95%;

      h4 {
        opacity: $defaultOpacity;
      }
    }

    .topics-container {
      margin: 1.5rem 0;

      img {
        height: 25px;
        margin: 0 .5rem 0 1rem;
      }
    }
  }

  .box-right {
    position: relative;

    .details-container {
      width: 300px;
      height: 400px;
      object-fit: contain;
      object-position: center center;

      img {
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translateY(-3rem);
        max-height: 500px;
        max-width: 350px;
      }
    }

    .box-footer {
      position: absolute;
      left: -30px;
      top: 300px;
      width: 330px;
      min-height: 140px;
      background-color: black;
      padding: 1.4rem;

      .nav-container {
        img {
          padding: .6rem .6rem .8rem;
          transform: translate(-.6rem);
        }
      }
    }
  }

  .box-explanation {
    position: absolute;
    right: 3rem;
    bottom: 6rem;
    transform: translateX(100%);

    h3, div:not(.RoundButton) {
      width: fit-content;
      margin: unset;
    }

    & > h3 {
      padding: .8rem 1rem;
      background-color: white;
      color: black;
    }

    div:not(.RoundButton) {
      background-color: black;
      padding: .6rem 1rem;
    }

    &.type-work {
      left: 2rem;
      transform: translateX(-100%);
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      & > h3 {
        background-color: $clrHighlight;
      }
    }
  }
}
