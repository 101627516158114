.PaginationList {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  .pagination-button {
    width: 6px;
    height: 6px;
    background-color: white;
    border-radius: 50%;
    margin: .2rem;
  }
}
