.HoverPoint {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;

  .circle {
    position: relative;
    transition: 0.4s ease-in-out;
    background-color: white;
    border-radius: 50%;

    &.outer-circle {
      width: 100%;
      height: 100%;
      opacity: $defaultOpacity;
    }

    &.inner-circle {
      position: absolute;
      width: 6px;
      height: 6px;
      border: 3px white solid;
    }
  }

  &.is-active {
    .circle {
      &.outer-circle {
        transform: scale(1.1);
        transform-origin: center;

      }

      &.inner-circle {
        transform: scale(0.7);
        transform-origin: center;
      }
    }
  }
}
