@import url('https://fonts.googleapis.com/css?family=Merriweather|Open+Sans&display=swap');

html {
  font-family: 'Open Sans', sans-serif;
  cursor: default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  body {
    margin: 0;
    color: white;


    h1, h2, h3, h4 {
      text-transform: uppercase;
    }

    h1 {
      font-family: Merriweather, serif;
      font-size: 2.2rem;
      font-weight: normal;
      margin: 0.5rem 0 1rem;
      line-height: 3.2rem;
      letter-spacing: .3rem;
    }

    h2 {
      font-weight: 700;
      font-size: 1.5rem;
      margin: 0.3rem 0 0.8rem;
    }

    h3 {
      font-size: 1rem;
      margin: 0.2rem 0 0.6rem;

      &.large {
        font-weight: 700;
      }
    }

    h4 {
      font-weight: 600;
      font-size: 0.8rem;
      margin: 0.1rem 0 0.4rem;
    }

    p {
      margin: unset;
      font-family: "Open Sans", sans-serif;

      &.highlighted {
        text-transform: uppercase;
        font-size: 20px;
      }

      &.large {
        font-size: 18px;
      }

      &.small {
        font-size: 14px;
        line-height: 1.3em;
      }

      &.transparent {
        text-transform: uppercase;
        opacity: 0.5;
      }
    }

    .secondary-title {
      margin-bottom: 1rem;
    }

    .main-content {
      margin-bottom: 2rem;
    }

    @media screen and (max-width: 1200px) {
      h1 {
        font-size: 2rem;
      }

      h2 {
        font-size: 1.5rem;
      }

      h3 {
        font-size: 1rem;
      }
    }

    a {
      &:hover {
        font-weight: bolder;
      }
    }

    button, .button {
      background-color: transparent;
      user-select: none;

      &:focus {
        outline: unset;
      }

      &:hover {
        cursor: pointer;
      }

      &.border-button {
        border: $lineThick white solid;

        &:not(.disabled):hover {
          background: $backgroundTransparentLight;
        }
      }

      &.disabled {
        cursor: default;
        opacity: $defaultOpacity;
      }

      &.inactive {
        cursor: pointer;
        opacity: $defaultOpacity;
      }
    }

    .full-screen {
      width: 100vw;
      height: 100vh;
      overflow: hidden;

      // TODO: add real detection for mobile screens
      @media screen and (max-aspect-ratio: 3/4) {
        height: calc(100vh - 72px);
      }
    }

    .transparent-filter:after {
      position: absolute;
      pointer-events: none;
      content: '';
      display: block;
      bottom: 0;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, $backgroundFilter 50%);
      width: 100%;
      height: 100%;
    }

    .horizontal-container {
      display: flex;
      align-items: center;
    }

    .vertical-container {
      display: flex;
      flex-direction: column;
    }
  }

  .fade-in {
    opacity: 1;
    transition: .3s ease-out;
  }

  .fade-out {
    opacity: 0;
    transition: .3s ease-out;
  }

  @keyframes glow {
    to {
      opacity: 1;
      transform: scale(1.04);
      box-shadow: 0 0 8px 6px #fff3;
    }
  }

  @keyframes glow-strong {
    to {
      opacity: 1;
      transform: scale(1.06);
      box-shadow: 0 0 10px 8px #fff3;
    }
  }
}
