.VideoSlide {
  display: flex;
  justify-content: center;
  align-items: center;

  .anim-group-container {
    &.hide {
      opacity: 0;
    }
  }

  .progress-indicator-container {
    position: absolute;
    transform: translateX(-50%);
    left: 50vw;
    bottom: 3vw;
    transition: opacity ease-in-out .3s;

    &.not-started {
      opacity: 0.7;
    }
  }
}
