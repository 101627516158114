.InfoLabel {
  position: absolute;
  text-align: left;

  &.right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  p {
    font-size: 0.8rem;
    text-transform: uppercase;
  }

  h4 {
    font-size: 0.9rem;
    font-weight: 800;
    margin: unset;
  }
}
