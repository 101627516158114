.SlideInContainer {
  position: absolute;
  top: 0;
  width: 70vw;
  max-width: 700px;
  height: 100vh;
  display: flex;
  flex-direction: row;

  &.slide-in-from-right {
    right: 0;
    padding-left: 5rem;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.8) 90%);
    animation: 1s slide-in-from-right ease-out;

    .scroll-container {
      padding: 10vh 2rem 6em 6em;
    }
  }

  &.slide-in-from-left {
    left: 0;
    padding-right: 5rem;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.8) 90%);
    animation: 1s slide-in-from-left ease-out;

    .scroll-container {
      padding: 4rem 6em 6em 2rem;
    }
  }

  .scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  .scroll-container {
    position: relative;
    overflow-y: scroll;
    -ms-overflow-style: none;

    .ScrollIndicator {
      position: fixed;
      bottom: 0;
      right: 0;
      margin: 3rem;
    }
  }

  & > .RoundButton {
    position: absolute;
    top: 10vh;
  }

  &.slide-in-from-left > .RoundButton {
    left: 5vw;
    transform: rotate(180deg);
  }

  @keyframes slide-in-from-right {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slide-in-from-left {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
}
