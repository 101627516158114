.AnimatedSVG {

  svg {
    pointer-events: none; // hides the tooltip when hovering
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &.is-active {
    polyline, polygon, line, path {
      animation: drawStrokes 4s linear forwards;
    }
  }

  @keyframes drawStrokes {
    to {
      stroke-dashoffset: 0;
    }
  }
}
