.TimeLine {
  display: flex;
  flex-direction: row;

  img {
    padding-left: 0.7rem;
  }

  .timeline-items {
    .item {
      display: flex;
      flex-direction: row;
      align-items: center;

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.6rem;
        border-radius: 50%;

        &:hover span {
          opacity: 1;
          background-color: $clrHighlight;
        }

        span {
          width: 6px;
          height: 6px;
          transition: .2s ease-in-out;
          border-radius: 50%;
          background-color: white;
          overflow: hidden;
          border: unset;

          &.transparent {
            opacity: 0.5;
          }
        }

        &.large-point span {
          width: .7rem;
          height: .7rem;
        }
      }

      .horizontal-line {
        background-color: white;
        height: $lineThin;
        width: 1.2rem;

        &.transparent {
          opacity: 0;
        }

        &.animate {
          animation: progress linear forwards;
        }

        @keyframes progress {
          from {
            width: 0;
            margin-right: 1.2rem;
          }
          to {
            width: 1.2rem;
            margin-right: 0;
          }
        }
      }
    }
  }
}
