.PopupContainer {
  background-color: $backgroundTransparentDark;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: .3s fade-in ease-out;

  .content-container {
    position: relative;

    & > .RoundButton {
      position: absolute;
      top: 0;
      transform: translateX(-120%);
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
