.RectButton {

  .border-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 2rem;

    &.glow {
      animation: glow ease-in-out 1.5s alternate infinite;
    }
  }

  p {
    font-size: 14px;
    margin: 0;
  }
}
