.TravelingDistancePage {
  text-align: left;

  .chip-container {
    display: flex;
    justify-content: flex-start;
    margin: 1rem 0 2rem 0;
  }

  .map {
    position: relative;

    svg {
      pointer-events: none;
      width: 100%;
      height: 100%;

      .city {
        pointer-events: all;
      }
    }

    .legende-container {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      flex-direction: column;

      .travelling-legende {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        opacity: 0;

        &.is-active {
          opacity: 1;
        }
      }

      .color-point {
        width: .5rem;
        height: .5rem;
        border-radius: 50%;
        margin-right: 1rem;
        margin-left: .5rem;

        &.train {
          background-color: $clrHighlight;
        }

        &.car {
          background-color: #FF0000;
        }
      }

      .legende-label {
        text-transform: uppercase;
        font-size: .8rem;
        font-weight: bold;
      }
    }
  }

  .map-info {
    font-size: .8rem;
    margin-left: 1rem;

    & > p {
      font-size: 1rem;
      margin: 0.5rem 0;
    }
  }

  polyline {
    animation: drawStrokes linear forwards;
    animation-timing-function: linear;
  }

  @keyframes drawStrokes {
    to {
      stroke-dashoffset: 0;
    }
  }

  .travel-times-container {
    display: flex;

    .train {
      width: 100%;

      &.is-hidden {
        display: none;
      }

      .icon-container {
        min-width: 3rem;
        height: 3rem;
        background-color: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("../../../../res/icons/train.svg");
        background-repeat: no-repeat;
        background-position: center;

      }

      .active-bar {
        background-color: $clrHighlight;
        height: .5rem;
        border-radius: 2rem;
        transition: width 2s;
      }
    }

    .car {
      width: 100%;

      &.is-hidden {
        display: none;
      }

      .icon-container {
        min-width: 3rem;
        height: 3rem;
        background-color: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("../../../../res/icons/car.svg");
        background-repeat: no-repeat;
        background-position: center;

      }

      .active-bar {
        background-color: #FF0000;
        height: .5rem;
        border-radius: 2rem;
        transition: width 2s;
      }
    }

    .travel-distances {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 1rem 1rem 0 1rem;
      transition: all 3s;


      .background-bar {
        max-width: 100%;
        height: .5rem;
        border-radius: 2rem;
      }
    }

    .content-container {
      width: 100%;
      margin-left: 1rem;

      .label-container {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

