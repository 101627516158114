@import "../variables";
/**
 * Insert all stylesheets of components here
 * (must be below variables.scss, but after that the order does not matter)
 */
@import '../index.scss';
@import '../components/containers/BackgroundVideoContainer';
@import '../components/containers/AnimatedSVG';
@import '../components/containers/PopupContainer';
@import '../components/containers/SlideInContainer';
@import '../components/buttons/HoverPoint.scss';
@import '../components/buttons/PaginationList.scss';
@import '../components/buttons/RectButton.scss';
@import '../components/buttons/SelectionChip';
@import '../components/buttons/RoundButton.scss';
@import '../components/buttons/NavBackButton.scss';
@import '../components/labels/EntryLabel.scss';
@import '../components/labels/PoiLabel';
@import '../components/labels/InfoLabel';
@import '../components/widgets/GraphicChart.scss';
@import '../components/widgets/Slider.scss';
@import '../components/widgets/ScrollIndicator.scss';
@import '../components/widgets/ProgressIndicator.scss';
@import '../components/widgets/Quiz.scss';
@import '../components/widgets/QuizBox.scss';
@import '../components/widgets/TimeLine.scss';
@import '../pages/intro/IntroductionPage.scss';
@import '../pages/about/AboutPage';
@import '../pages/video/LocationDescription';
@import '../pages/video/VideoPage';
@import '../pages/video/VideoSlide';
@import '../pages/video/overlays/PopupOverlay';
@import '../pages/video/overlays/SlideInOverlay';
@import '../pages/video/overlays/special-overlay-data/TravelingDistancePage';
@import '../pages/menu/MenuPage';

.App {
  text-align: center;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > .PopupContainer {
    display: flex;
    justify-content: center;

    .content-container {
      width: 90vw;
      border-radius: .2rem;
      background-color: #222;
      padding: 3rem 2rem 2rem;

      .RoundButton {
        transform: unset;
        position: unset;
        margin: 2rem auto;
      }

      h2 {
        padding: .5rem 0;
      }

      p {
        padding-bottom: .5rem;
      }
    }
  }
}
