@import url(https://fonts.googleapis.com/css?family=Merriweather|Open+Sans&display=swap);
/**
 * Insert all stylesheets of components here
 * (must be below variables.scss, but after that the order does not matter)
 */
html {
  font-family: 'Open Sans', sans-serif;
  cursor: default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  html body {
    margin: 0;
    color: white; }
    html body h1, html body h2, html body h3, html body h4 {
      text-transform: uppercase; }
    html body h1 {
      font-family: Merriweather, serif;
      font-size: 2.2rem;
      font-weight: normal;
      margin: 0.5rem 0 1rem;
      line-height: 3.2rem;
      letter-spacing: .3rem; }
    html body h2 {
      font-weight: 700;
      font-size: 1.5rem;
      margin: 0.3rem 0 0.8rem; }
    html body h3 {
      font-size: 1rem;
      margin: 0.2rem 0 0.6rem; }
      html body h3.large {
        font-weight: 700; }
    html body h4 {
      font-weight: 600;
      font-size: 0.8rem;
      margin: 0.1rem 0 0.4rem; }
    html body p {
      margin: unset;
      font-family: "Open Sans", sans-serif; }
      html body p.highlighted {
        text-transform: uppercase;
        font-size: 20px; }
      html body p.large {
        font-size: 18px; }
      html body p.small {
        font-size: 14px;
        line-height: 1.3em; }
      html body p.transparent {
        text-transform: uppercase;
        opacity: 0.5; }
    html body .secondary-title {
      margin-bottom: 1rem; }
    html body .main-content {
      margin-bottom: 2rem; }
    @media screen and (max-width: 1200px) {
      html body h1 {
        font-size: 2rem; }
      html body h2 {
        font-size: 1.5rem; }
      html body h3 {
        font-size: 1rem; } }
    html body a:hover {
      font-weight: bolder; }
    html body button, html body .button {
      background-color: transparent;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
      html body button:focus, html body .button:focus {
        outline: unset; }
      html body button:hover, html body .button:hover {
        cursor: pointer; }
      html body button.border-button, html body .button.border-button {
        border: 2px white solid; }
        html body button.border-button:not(.disabled):hover, html body .button.border-button:not(.disabled):hover {
          background: rgba(255, 255, 255, 0.2); }
      html body button.disabled, html body .button.disabled {
        cursor: default;
        opacity: 0.4; }
      html body button.inactive, html body .button.inactive {
        cursor: pointer;
        opacity: 0.4; }
    html body .full-screen {
      width: 100vw;
      height: 100vh;
      overflow: hidden; }
      @media screen and (max-aspect-ratio: 3 / 4) {
        html body .full-screen {
          height: calc(100vh - 72px); } }
    html body .transparent-filter:after {
      position: absolute;
      pointer-events: none;
      content: '';
      display: block;
      bottom: 0;
      background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.7)), color-stop(50%, #0002));
      background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, #0002 50%);
      width: 100%;
      height: 100%; }
    html body .horizontal-container {
      display: flex;
      align-items: center; }
    html body .vertical-container {
      display: flex;
      flex-direction: column; }
  html .fade-in {
    opacity: 1;
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out; }
  html .fade-out {
    opacity: 0;
    -webkit-transition: .3s ease-out;
    transition: .3s ease-out; }

@-webkit-keyframes glow {
  to {
    opacity: 1;
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
    box-shadow: 0 0 8px 6px #fff3; } }

@keyframes glow {
  to {
    opacity: 1;
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
    box-shadow: 0 0 8px 6px #fff3; } }

@-webkit-keyframes glow-strong {
  to {
    opacity: 1;
    -webkit-transform: scale(1.06);
            transform: scale(1.06);
    box-shadow: 0 0 10px 8px #fff3; } }

@keyframes glow-strong {
  to {
    opacity: 1;
    -webkit-transform: scale(1.06);
            transform: scale(1.06);
    box-shadow: 0 0 10px 8px #fff3; } }

.BackgroundVideoContainer .outer-container .inner-container {
  position: relative; }

.BackgroundVideoContainer .loading-indicator {
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out; }

.AnimatedSVG svg {
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.AnimatedSVG.is-active polyline, .AnimatedSVG.is-active polygon, .AnimatedSVG.is-active line, .AnimatedSVG.is-active path {
  -webkit-animation: drawStrokes 4s linear forwards;
          animation: drawStrokes 4s linear forwards; }

@-webkit-keyframes drawStrokes {
  to {
    stroke-dashoffset: 0; } }

@keyframes drawStrokes {
  to {
    stroke-dashoffset: 0; } }

.PopupContainer {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: .3s fade-in ease-out;
          animation: .3s fade-in ease-out; }
  .PopupContainer .content-container {
    position: relative; }
    .PopupContainer .content-container > .RoundButton {
      position: absolute;
      top: 0;
      -webkit-transform: translateX(-120%);
              transform: translateX(-120%); }

@-webkit-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.SlideInContainer {
  position: absolute;
  top: 0;
  width: 70vw;
  max-width: 700px;
  height: 100vh;
  display: flex;
  flex-direction: row; }
  .SlideInContainer.slide-in-from-right {
    right: 0;
    padding-left: 5rem;
    background: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.01)), color-stop(90%, rgba(0, 0, 0, 0.8)));
    background: linear-gradient(to right, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.8) 90%);
    -webkit-animation: 1s slide-in-from-right ease-out;
            animation: 1s slide-in-from-right ease-out; }
    .SlideInContainer.slide-in-from-right .scroll-container {
      padding: 10vh 2rem 6em 6em; }
  .SlideInContainer.slide-in-from-left {
    left: 0;
    padding-right: 5rem;
    background: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.01)), color-stop(90%, rgba(0, 0, 0, 0.8)));
    background: linear-gradient(to left, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.8) 90%);
    -webkit-animation: 1s slide-in-from-left ease-out;
            animation: 1s slide-in-from-left ease-out; }
    .SlideInContainer.slide-in-from-left .scroll-container {
      padding: 4rem 6em 6em 2rem; }
  .SlideInContainer .scroll-container::-webkit-scrollbar {
    display: none; }
  .SlideInContainer .scroll-container {
    position: relative;
    overflow-y: scroll;
    -ms-overflow-style: none; }
    .SlideInContainer .scroll-container .ScrollIndicator {
      position: fixed;
      bottom: 0;
      right: 0;
      margin: 3rem; }
  .SlideInContainer > .RoundButton {
    position: absolute;
    top: 10vh; }
  .SlideInContainer.slide-in-from-left > .RoundButton {
    left: 5vw;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

@-webkit-keyframes slide-in-from-right {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes slide-in-from-right {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@-webkit-keyframes slide-in-from-left {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

@keyframes slide-in-from-left {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0); } }

.HoverPoint {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px; }
  .HoverPoint .circle {
    position: relative;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    background-color: white;
    border-radius: 50%; }
    .HoverPoint .circle.outer-circle {
      width: 100%;
      height: 100%;
      opacity: 0.4; }
    .HoverPoint .circle.inner-circle {
      position: absolute;
      width: 6px;
      height: 6px;
      border: 3px white solid; }
  .HoverPoint.is-active .circle.outer-circle {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transform-origin: center;
            transform-origin: center; }
  .HoverPoint.is-active .circle.inner-circle {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    -webkit-transform-origin: center;
            transform-origin: center; }

.PaginationList {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row; }
  .PaginationList .pagination-button {
    width: 6px;
    height: 6px;
    background-color: white;
    border-radius: 50%;
    margin: .2rem; }

.RectButton .border-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 2rem; }
  .RectButton .border-button.glow {
    -webkit-animation: glow ease-in-out 1.5s alternate infinite;
            animation: glow ease-in-out 1.5s alternate infinite; }

.RectButton p {
  font-size: 14px;
  margin: 0; }

.SelectionChip {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  margin-right: 1rem;
  color: #4a5374; }
  .SelectionChip p {
    text-transform: uppercase;
    margin: 0;
    font-size: .8rem;
    font-weight: bold;
    padding: .2rem 1rem .2rem 1rem; }
  .SelectionChip.is-active {
    background-color: white; }
  .SelectionChip.is-inactive {
    color: #fff8; }

.RoundButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 50%; }
  .RoundButton p {
    margin: 0; }
  .RoundButton img {
    max-width: 60%; }

.NavBackButton p {
  margin-left: 1rem; }

.NavBackButton:hover {
  text-decoration: underline; }

.EntryLabel {
  position: relative; }
  .EntryLabel h3 {
    padding-bottom: .3rem;
    font-weight: 600;
    margin: unset;
    border-bottom: 1px solid white; }
  .EntryLabel .label-line {
    position: absolute;
    border-bottom: 1px solid white; }
  .EntryLabel.right .label-line {
    right: 0; }

.PoiLabel {
  position: absolute;
  text-align: left;
  white-space: nowrap; }
  .PoiLabel p {
    font-size: 0.8rem;
    text-transform: uppercase; }
  .PoiLabel h4 {
    font-size: 0.9rem;
    border-bottom: 1px solid white;
    font-weight: 800;
    margin: unset;
    padding-bottom: 0.2em; }
  .PoiLabel .RoundButton {
    border-width: 1px; }
  .PoiLabel .label-line {
    position: absolute;
    border-bottom: 1px solid white; }
    .PoiLabel .label-line.right {
      right: 0; }

.InfoLabel {
  position: absolute;
  text-align: left; }
  .InfoLabel.right {
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
  .InfoLabel p {
    font-size: 0.8rem;
    text-transform: uppercase; }
  .InfoLabel h4 {
    font-size: 0.9rem;
    font-weight: 800;
    margin: unset; }

.GraphicChart .label-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: unset;
  list-style: none; }
  .GraphicChart .label-container li {
    position: relative;
    margin-bottom: .3rem; }
    .GraphicChart .label-container li .value {
      display: flex;
      align-items: baseline; }
    .GraphicChart .label-container li::before {
      position: absolute;
      left: -1.3rem;
      content: "\2022"; }
    .GraphicChart .label-container li h2 {
      margin: unset; }
    .GraphicChart .label-container li.is-active::before {
      color: white;
      font-size: 1.6rem;
      font-weight: bolder; }
    .GraphicChart .label-container li.is-inactive {
      color: white;
      opacity: 0.4; }
      .GraphicChart .label-container li.is-inactive h2 {
        font-size: 1.2rem; }

.Slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 1rem;
  border-width: 1px !important;
  padding: 0 0.5rem 0 1rem; }
  .Slider img {
    padding: .5rem; }
  .Slider p {
    text-transform: uppercase;
    font-size: 14px; }

.ScrollIndicator {
  display: flex;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  pointer-events: none; }
  .ScrollIndicator.show {
    -webkit-animation: 3s blink ease-in-out infinite;
            animation: 3s blink ease-in-out infinite; }
  .ScrollIndicator.hide {
    -webkit-animation: unset;
            animation: unset; }
  .ScrollIndicator svg {
    height: 60px; }
  .ScrollIndicator h4 {
    margin-top: 0.4rem;
    font-size: 0.7rem; }

@-webkit-keyframes blink {
  0% {
    opacity: 0; }
  30% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes blink {
  0% {
    opacity: 0; }
  30% {
    opacity: 1; }
  80% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.ProgressIndicator {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center; }
  .ProgressIndicator:hover {
    background: rgba(255, 255, 255, 0.2); }
  .ProgressIndicator.glow {
    -webkit-transition: opacity 1s linear;
    transition: opacity 1s linear;
    opacity: .8;
    -webkit-animation: glow-strong ease-in-out 1.5s 1s alternate infinite;
            animation: glow-strong ease-in-out 1.5s 1s alternate infinite; }
  .ProgressIndicator svg {
    width: 4rem;
    height: 4rem;
    pointer-events: none; }
    .ProgressIndicator svg.hide path {
      opacity: 0; }
    .ProgressIndicator svg path {
      stroke: white;
      stroke-width: 2px;
      -webkit-animation: draw-strokes linear forwards;
              animation: draw-strokes linear forwards; }
  .ProgressIndicator .icon {
    position: absolute;
    width: 12px; }

@-webkit-keyframes draw-strokes {
  to {
    stroke-dashoffset: 0; } }

@keyframes draw-strokes {
  to {
    stroke-dashoffset: 0; } }

.Quiz {
  width: 350px;
  display: flex;
  flex-direction: row;
  -webkit-transition: .5s ease-in opacity;
  transition: .5s ease-in opacity; }
  .Quiz.open {
    opacity: 1; }
  .Quiz.closed {
    opacity: 0;
    pointer-events: none; }
  .Quiz .border-line {
    margin: 1rem 0;
    padding: 0.8rem;
    border-left: 1px white solid; }
  .Quiz .text-container {
    position: relative; }
    .Quiz .text-container p {
      text-align: left; }
    .Quiz .text-container .choice-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .Quiz .text-container .choice-container .RectButton {
        width: 48%; }
        .Quiz .text-container .choice-container .RectButton > div {
          padding: 0.2rem;
          margin: 0.3rem 0 0.1rem; }
          .Quiz .text-container .choice-container .RectButton > div.disabled {
            -webkit-animation: flashWrongAnswer 0.4s ease-in-out;
                    animation: flashWrongAnswer 0.4s ease-in-out; }
    .Quiz .text-container .answer-container {
      background-color: rgba(0, 0, 0, 0.6);
      padding: 0 0.3em;
      margin-top: 0.4rem;
      position: absolute; }
  .Quiz.right {
    flex-direction: row-reverse; }
    .Quiz.right .border-line {
      border-left: unset;
      border-right: 1px white solid; }

@-webkit-keyframes flashWrongAnswer {
  from {
    opacity: 1;
    background-color: white; } }

@keyframes flashWrongAnswer {
  from {
    opacity: 1;
    background-color: white; } }

.QuizBox {
  display: flex;
  position: absolute;
  left: calc(50% - 6rem);
  top: calc(50% - 6rem);
  padding: 6rem; }
  .QuizBox.is-open {
    z-index: 100; }
  .QuizBox .label {
    display: flex; }
    .QuizBox .label .HoverPoint {
      -webkit-transform: translateY(-30px);
              transform: translateY(-30px); }
  .QuizBox.right .label {
    flex-direction: row-reverse; }

.TimeLine {
  display: flex;
  flex-direction: row; }
  .TimeLine img {
    padding-left: 0.7rem; }
  .TimeLine .timeline-items .item {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .TimeLine .timeline-items .item .button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.6rem;
      border-radius: 50%; }
      .TimeLine .timeline-items .item .button:hover span {
        opacity: 1;
        background-color: #7EE2D1; }
      .TimeLine .timeline-items .item .button span {
        width: 6px;
        height: 6px;
        -webkit-transition: .2s ease-in-out;
        transition: .2s ease-in-out;
        border-radius: 50%;
        background-color: white;
        overflow: hidden;
        border: unset; }
        .TimeLine .timeline-items .item .button span.transparent {
          opacity: 0.5; }
      .TimeLine .timeline-items .item .button.large-point span {
        width: .7rem;
        height: .7rem; }
    .TimeLine .timeline-items .item .horizontal-line {
      background-color: white;
      height: 1px;
      width: 1.2rem; }
      .TimeLine .timeline-items .item .horizontal-line.transparent {
        opacity: 0; }
      .TimeLine .timeline-items .item .horizontal-line.animate {
        -webkit-animation: progress linear forwards;
                animation: progress linear forwards; }

@-webkit-keyframes progress {
  from {
    width: 0;
    margin-right: 1.2rem; }
  to {
    width: 1.2rem;
    margin-right: 0; } }

@keyframes progress {
  from {
    width: 0;
    margin-right: 1.2rem; }
  to {
    width: 1.2rem;
    margin-right: 0; } }

.IntroductionPage {
  overflow: hidden;
  position: relative; }
  .IntroductionPage .about-us-container {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1rem 3rem;
    pointer-events: none;
    -webkit-transform: translateX(calc(-100% - 3rem));
            transform: translateX(calc(-100% - 3rem));
    -webkit-transition: ease-in-out .5s;
    transition: ease-in-out .5s;
    font-family: Merriweather, serif; }
    .IntroductionPage .about-us-container .about-us {
      font-family: Merriweather, serif;
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: .3rem;
      padding-left: .3rem;
      opacity: .8; }
    .IntroductionPage .about-us-container.show {
      pointer-events: all;
      -webkit-transform: translateX(0);
              transform: translateX(0); }
    .IntroductionPage .about-us-container h2 {
      border-bottom: 2px transparent solid; }
      .IntroductionPage .about-us-container h2:hover {
        border-bottom: 2px #7EE2D1 solid;
        opacity: 1; }
  .IntroductionPage .page-headline {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .IntroductionPage .slides-container {
    position: relative; }
    .IntroductionPage .slides-container .slide {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      -webkit-transition: opacity 0.5s ease-in-out;
      transition: opacity 0.5s ease-in-out;
      opacity: 0; }
      .IntroductionPage .slides-container .slide .background-image {
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%; }
      .IntroductionPage .slides-container .slide.isActive {
        opacity: 1; }
      .IntroductionPage .slides-container .slide:not(.isActive) {
        pointer-events: none; }
  .IntroductionPage .slide-navigation {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 1rem 4rem 1rem 1rem; }
    .IntroductionPage .slide-navigation .RoundButton {
      margin: 1rem; }
  .IntroductionPage .slide .bottom-element {
    position: absolute;
    vertical-align: center;
    bottom: 10vh;
    font-size: 1.2rem; }
  .IntroductionPage .slide.SlideLife .quiz-reputation {
    -webkit-transform: translate(-200px, -320px);
            transform: translate(-200px, -320px); }
  .IntroductionPage .slide.SlideLife .quiz-history {
    -webkit-transform: translate(320px, -310px);
            transform: translate(320px, -310px); }
  .IntroductionPage .slide.SlideLife .quiz-sport {
    -webkit-transform: translate(-660px, 40px);
            transform: translate(-660px, 40px); }
  .IntroductionPage .slide.SlideLife.isActive .quiz-reputation, .IntroductionPage .slide.SlideLife.isActive .quiz-history, .IntroductionPage .slide.SlideLife.isActive .quiz-sport {
    opacity: 0; }
  .IntroductionPage .slide.SlideLife.isActive .quiz-sport {
    -webkit-animation: fade-in 1s ease-in 5s forwards;
            animation: fade-in 1s ease-in 5s forwards; }
  .IntroductionPage .slide.SlideLife.isActive .quiz-history {
    -webkit-animation: fade-in 1s ease-in 6s forwards;
            animation: fade-in 1s ease-in 6s forwards; }
  .IntroductionPage .slide.SlideLife.isActive .quiz-reputation {
    -webkit-animation: fade-in 1s ease-in 7s forwards;
            animation: fade-in 1s ease-in 7s forwards; }
  .IntroductionPage .slide.SlideWork .quiz-companies {
    -webkit-transform: translate(-560px, -90px);
            transform: translate(-560px, -90px); }
  .IntroductionPage .slide.SlideWork .quiz-jobs {
    -webkit-transform: translate(220px, -150px);
            transform: translate(220px, -150px); }
  .IntroductionPage .slide.SlideWork .bottom-element.text-container, .IntroductionPage .slide.SlideWork .bottom-element.go-to-main-button {
    opacity: 0; }
  .IntroductionPage .slide.SlideWork.isActive .quiz-companies, .IntroductionPage .slide.SlideWork.isActive .quiz-jobs {
    opacity: 0; }
  .IntroductionPage .slide.SlideWork.isActive .quiz-companies {
    -webkit-animation: fade-in 1s ease-in 4s forwards;
            animation: fade-in 1s ease-in 4s forwards; }
  .IntroductionPage .slide.SlideWork.isActive .quiz-jobs {
    -webkit-animation: fade-in 1s ease-in 5s forwards;
            animation: fade-in 1s ease-in 5s forwards; }
  .IntroductionPage .slide.SlideWork.isActive .bottom-element.text-container, .IntroductionPage .slide.SlideWork.isActive .bottom-element.go-to-main-button {
    opacity: 0; }
  .IntroductionPage .slide.SlideWork.isActive .bottom-element.text-container {
    opacity: 1;
    -webkit-animation: move-up 1s ease-out 5s forwards;
            animation: move-up 1s ease-out 5s forwards; }

@-webkit-keyframes move-up {
  to {
    -webkit-transform: translateY(-2.5rem);
            transform: translateY(-2.5rem); } }

@keyframes move-up {
  to {
    -webkit-transform: translateY(-2.5rem);
            transform: translateY(-2.5rem); } }
  .IntroductionPage .slide.SlideWork.isActive .bottom-element.go-to-main-button {
    text-transform: uppercase;
    opacity: 0;
    padding: 0.5rem 3.5rem;
    -webkit-animation: fade-in 1s ease-in 6s forwards;
            animation: fade-in 1s ease-in 6s forwards;
    -webkit-transform: translateY(1.5rem);
            transform: translateY(1.5rem);
    -webkit-animation-iteration-count: 1, infinite;
            animation-iteration-count: 1, infinite; }
    .IntroductionPage .slide.SlideWork.isActive .bottom-element.go-to-main-button p {
      font-size: 1rem; }

@-webkit-keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.AboutPage {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0; }
  .AboutPage h3 {
    margin: 2rem 0 .5rem 0; }
  .AboutPage h4 span {
    text-transform: none; }
  .AboutPage a {
    text-decoration: unset;
    color: #7EE2D1; }
    .AboutPage a:hover {
      font-weight: bolder;
      border-bottom: 2px #7EE2D1; }
  .AboutPage .authors-container {
    position: absolute;
    bottom: 0;
    right: 8vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 3rem 5rem;
    -webkit-animation: 1s move-in ease-in-out 3s forwards;
            animation: 1s move-in ease-in-out 3s forwards;
    -webkit-transform: translateY(150%);
            transform: translateY(150%); }
    .AboutPage .authors-container .authors-svg {
      width: 20vw; }

@-webkit-keyframes move-in {
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes move-in {
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }
  .AboutPage .scroll-container {
    padding: 10vh 0 6em 10rem !important; }
    .AboutPage .scroll-container .RoundButton {
      margin-left: .5rem; }
  .AboutPage .ScrollIndicator {
    display: none; }

.LocationDescription {
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: left;
  margin: 0 0 2rem 4rem; }
  .LocationDescription .horizontal-container {
    text-align: center;
    padding-top: .5rem;
    align-items: flex-end; }
    .LocationDescription .horizontal-container > :not(:last-child) {
      margin-right: 1.5rem; }
    .LocationDescription .horizontal-container .icon-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between; }
      .LocationDescription .horizontal-container .icon-container img {
        max-width: 36px;
        padding-bottom: .4rem; }

.VideoPage .background-image {
  position: absolute;
  background-position: center;
  background-size: cover; }

.VideoPage .timeline-container {
  -webkit-transition: .3s ease-in;
  transition: .3s ease-in; }
  .VideoPage .timeline-container .TimeLine {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0 4rem 2rem 0; }

.VideoSlide {
  display: flex;
  justify-content: center;
  align-items: center; }
  .VideoSlide .anim-group-container.hide {
    opacity: 0; }
  .VideoSlide .progress-indicator-container {
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    left: 50vw;
    bottom: 3vw;
    -webkit-transition: opacity ease-in-out .3s;
    transition: opacity ease-in-out .3s; }
    .VideoSlide .progress-indicator-container.not-started {
      opacity: 0.7; }

.PopupOverlay {
  position: relative;
  max-width: 800px;
  background-color: #343741;
  text-align: left;
  padding: 3rem 2rem 1.5rem 3rem; }
  .PopupOverlay span {
    opacity: 0.4; }
  .PopupOverlay .RoundButton {
    margin: 0 0 0.5rem 0.3rem; }
  .PopupOverlay .box-left {
    margin-right: 1rem;
    width: 400px; }
    .PopupOverlay .box-left .header-container {
      max-width: 95%; }
      .PopupOverlay .box-left .header-container h4 {
        opacity: 0.4; }
    .PopupOverlay .box-left .topics-container {
      margin: 1.5rem 0; }
      .PopupOverlay .box-left .topics-container img {
        height: 25px;
        margin: 0 .5rem 0 1rem; }
  .PopupOverlay .box-right {
    position: relative; }
    .PopupOverlay .box-right .details-container {
      width: 300px;
      height: 400px;
      object-fit: contain;
      object-position: center center; }
      .PopupOverlay .box-right .details-container img {
        position: absolute;
        right: 0;
        bottom: 0;
        -webkit-transform: translateY(-3rem);
                transform: translateY(-3rem);
        max-height: 500px;
        max-width: 350px; }
    .PopupOverlay .box-right .box-footer {
      position: absolute;
      left: -30px;
      top: 300px;
      width: 330px;
      min-height: 140px;
      background-color: black;
      padding: 1.4rem; }
      .PopupOverlay .box-right .box-footer .nav-container img {
        padding: .6rem .6rem .8rem;
        -webkit-transform: translate(-0.6rem);
                transform: translate(-0.6rem); }
  .PopupOverlay .box-explanation {
    position: absolute;
    right: 3rem;
    bottom: 6rem;
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
    .PopupOverlay .box-explanation h3, .PopupOverlay .box-explanation div:not(.RoundButton) {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin: unset; }
    .PopupOverlay .box-explanation > h3 {
      padding: .8rem 1rem;
      background-color: white;
      color: black; }
    .PopupOverlay .box-explanation div:not(.RoundButton) {
      background-color: black;
      padding: .6rem 1rem; }
    .PopupOverlay .box-explanation.type-work {
      left: 2rem;
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      display: flex;
      align-items: flex-end;
      flex-direction: column; }
      .PopupOverlay .box-explanation.type-work > h3 {
        background-color: #7EE2D1; }

.SlideInContainer {
  text-align: left; }
  .SlideInContainer .image-container {
    width: 100%;
    height: 400px;
    object-position: center center;
    margin-left: 2rem; }
    .SlideInContainer .image-container img {
      float: left;
      object-fit: cover;
      width: 100%;
      height: 100%;
      -webkit-filter: brightness(0.8);
              filter: brightness(0.8); }
  .SlideInContainer .location-container {
    display: flex;
    align-items: flex-start;
    margin-top: -2rem; }
  .SlideInContainer .description-container {
    margin-left: 2rem; }
  .SlideInContainer .facts-container {
    -webkit-transform: translateY(-4rem);
            transform: translateY(-4rem); }

.TravelingDistancePage {
  text-align: left; }
  .TravelingDistancePage .chip-container {
    display: flex;
    justify-content: flex-start;
    margin: 1rem 0 2rem 0; }
  .TravelingDistancePage .map {
    position: relative; }
    .TravelingDistancePage .map svg {
      pointer-events: none;
      width: 100%;
      height: 100%; }
      .TravelingDistancePage .map svg .city {
        pointer-events: all; }
    .TravelingDistancePage .map .legende-container {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      flex-direction: column; }
      .TravelingDistancePage .map .legende-container .travelling-legende {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        opacity: 0; }
        .TravelingDistancePage .map .legende-container .travelling-legende.is-active {
          opacity: 1; }
      .TravelingDistancePage .map .legende-container .color-point {
        width: .5rem;
        height: .5rem;
        border-radius: 50%;
        margin-right: 1rem;
        margin-left: .5rem; }
        .TravelingDistancePage .map .legende-container .color-point.train {
          background-color: #7EE2D1; }
        .TravelingDistancePage .map .legende-container .color-point.car {
          background-color: #FF0000; }
      .TravelingDistancePage .map .legende-container .legende-label {
        text-transform: uppercase;
        font-size: .8rem;
        font-weight: bold; }
  .TravelingDistancePage .map-info {
    font-size: .8rem;
    margin-left: 1rem; }
    .TravelingDistancePage .map-info > p {
      font-size: 1rem;
      margin: 0.5rem 0; }
  .TravelingDistancePage polyline {
    -webkit-animation: drawStrokes linear forwards;
            animation: drawStrokes linear forwards;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear; }

@keyframes drawStrokes {
  to {
    stroke-dashoffset: 0; } }
  .TravelingDistancePage .travel-times-container {
    display: flex; }
    .TravelingDistancePage .travel-times-container .train {
      width: 100%; }
      .TravelingDistancePage .travel-times-container .train.is-hidden {
        display: none; }
      .TravelingDistancePage .travel-times-container .train .icon-container {
        min-width: 3rem;
        height: 3rem;
        background-color: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url(/static/media/train.f4f51ce9.svg);
        background-repeat: no-repeat;
        background-position: center; }
      .TravelingDistancePage .travel-times-container .train .active-bar {
        background-color: #7EE2D1;
        height: .5rem;
        border-radius: 2rem;
        -webkit-transition: width 2s;
        transition: width 2s; }
    .TravelingDistancePage .travel-times-container .car {
      width: 100%; }
      .TravelingDistancePage .travel-times-container .car.is-hidden {
        display: none; }
      .TravelingDistancePage .travel-times-container .car .icon-container {
        min-width: 3rem;
        height: 3rem;
        background-color: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url(/static/media/car.6bbb9cb0.svg);
        background-repeat: no-repeat;
        background-position: center; }
      .TravelingDistancePage .travel-times-container .car .active-bar {
        background-color: #FF0000;
        height: .5rem;
        border-radius: 2rem;
        -webkit-transition: width 2s;
        transition: width 2s; }
    .TravelingDistancePage .travel-times-container .travel-distances {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 1rem 1rem 0 1rem;
      -webkit-transition: all 3s;
      transition: all 3s; }
      .TravelingDistancePage .travel-times-container .travel-distances .background-bar {
        max-width: 100%;
        height: .5rem;
        border-radius: 2rem; }
    .TravelingDistancePage .travel-times-container .content-container {
      width: 100%;
      margin-left: 1rem; }
      .TravelingDistancePage .travel-times-container .content-container .label-container {
        display: flex;
        justify-content: space-between; }

.MenuPage {
  background-size: cover;
  background-position: center; }
  .MenuPage svg #subgroup {
    -webkit-animation: linear infinite rotate 6s;
            animation: linear infinite rotate 6s;
    -webkit-transform-origin: center;
            transform-origin: center;
    transform-box: fill-box; }
  .MenuPage svg g {
    -webkit-transition: ease-in-out .5s;
    transition: ease-in-out .5s; }

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
  .MenuPage .SlideInContainer h3 {
    max-width: 400px;
    margin-top: 3rem;
    margin-bottom: .5rem; }
  .MenuPage .SlideInContainer p {
    max-width: 500px; }
  .MenuPage .SlideInContainer .scroll-container::-webkit-scrollbar {
    display: none; }
  .MenuPage .SlideInContainer .scroll-container {
    display: flex;
    -ms-overflow-style: none; }
    .MenuPage .SlideInContainer .scroll-container .HomeSlide {
      align-self: center; }
  .MenuPage .SlideInContainer .choose-container {
    margin-top: 3rem; }
  .MenuPage .SlideInContainer .selection-button-container {
    max-width: 500px;
    flex-wrap: wrap;
    margin-top: 1rem; }
    .MenuPage .SlideInContainer .selection-button-container .RectButton:not(:last-child) {
      margin-right: 1rem; }
  .MenuPage .SlideInContainer .selection-container {
    margin-top: .4rem; }
  .MenuPage .video-fade-container {
    -webkit-transition: linear;
    transition: linear;
    background-color: black; }
    .MenuPage .video-fade-container.show {
      opacity: 1; }
    .MenuPage .video-fade-container.hide {
      opacity: 0; }
  .MenuPage .NavBackButton {
    margin-bottom: 4rem; }
  .MenuPage .legend-svg {
    position: absolute;
    bottom: 1rem;
    right: 1rem; }

.App {
  text-align: center;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .App > .PopupContainer {
    display: flex;
    justify-content: center; }
    .App > .PopupContainer .content-container {
      width: 90vw;
      border-radius: .2rem;
      background-color: #222;
      padding: 3rem 2rem 2rem; }
      .App > .PopupContainer .content-container .RoundButton {
        -webkit-transform: unset;
                transform: unset;
        position: unset;
        margin: 2rem auto; }
      .App > .PopupContainer .content-container h2 {
        padding: .5rem 0; }
      .App > .PopupContainer .content-container p {
        padding-bottom: .5rem; }

