.AboutPage {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;

  h3 {
    margin: 2rem 0 .5rem 0;
  }

  h4 {
    span {
      text-transform: none;
    }
  }

  a {
    text-decoration: unset;
    color: $clrHighlight;

    &:hover {
      font-weight: bolder;
      border-bottom: 2px $clrHighlight;
    }
  }

  .authors-container {
    position: absolute;
    bottom: 0;
    right: 8vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 3rem 5rem;
    animation: 1s move-in ease-in-out 3s forwards;
    transform: translateY(150%);

    .authors-svg {
      width: 20vw;
    }

    @keyframes move-in {
      to {
        transform: translateY(0);

      }
    }
  }

  .scroll-container {
    padding: 10vh 0 6em 10rem !important;

    .RoundButton {
      margin-left: .5rem;
    }
  }

  .ScrollIndicator {
    display: none;
  }
}
