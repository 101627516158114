.RoundButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 50%;

  p {
    margin: 0;
  }

  img {
    max-width: 60%;
  }
}
