.Quiz {
  width: 350px;
  display: flex;
  flex-direction: row;
  transition: .5s ease-in opacity;

  &.open {
    opacity: 1;
  }

  &.closed {
    opacity: 0;
    pointer-events: none;
  }

  .border-line {
    margin: 1rem 0;
    padding: 0.8rem;
    border-left: $lineThin white solid;
  }

  .text-container {
    position: relative;

    p {
      text-align: left;
    }

    .choice-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .RectButton {
        width: 48%;

        & > div {
          padding: 0.2rem;
          margin: 0.3rem 0 0.1rem;

          &.disabled {
            animation: flashWrongAnswer 0.4s ease-in-out;
          }
        }
      }
    }

    .answer-container {
      background-color: $backgroundTransparentDark;
      padding: 0 0.3em;
      margin-top: 0.4rem;
      position: absolute;
    }
  }

  &.right {
    flex-direction: row-reverse;

    .border-line {
      border-left: unset;
      border-right: $lineThin white solid;
    }
  }

  @keyframes flashWrongAnswer {
    from {
      opacity: 1;
      background-color: white;
    }
  }
}
