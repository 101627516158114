.BackgroundVideoContainer {

  .outer-container {
    .inner-container {
      position: relative;
    }
  }

  .loading-indicator {
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s ease-in-out;
  }
}
