.SelectionChip {
  //border: $lineThick white solid;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  margin-right: 1rem;
  color: #4a5374;

  p {
    text-transform: uppercase;
    margin: 0;
    font-size: .8rem;
    font-weight: bold;
    padding: .2rem 1rem .2rem 1rem;
  }

  &.is-active {
    background-color: white;
  }

  &.is-inactive {
    color: #fff8;
  }
}
