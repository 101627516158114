.Slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 1rem;
  border-width: $lineThin !important;
  padding: 0 0.5rem 0 1rem;

  img {
    padding: .5rem;
  }

  p {
    text-transform: uppercase;
    font-size: 14px;
  }
}
