.MenuPage {
  background-size: cover;
  background-position: center;

  svg {
    #subgroup {
      animation: linear infinite rotate 6s;
      transform-origin: center;
      transform-box: fill-box;
    }

    g {
      transition: ease-in-out .5s;
    }

    @keyframes rotate {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }

  .SlideInContainer {
    h3 {
      max-width: 400px;
      margin-top: 3rem;
      margin-bottom: .5rem;
    }

    p {
      max-width: 500px;
    }

    .scroll-container::-webkit-scrollbar {
      display: none;
    }

    .scroll-container {
      display: flex;
      -ms-overflow-style: none;

      .HomeSlide {
        align-self: center;
      }
    }

    .choose-container {
      margin-top: 3rem;
    }

    .selection-button-container {
      max-width: 500px;
      flex-wrap: wrap;
      margin-top: 1rem;

      .RectButton {
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }

    .selection-container {
      margin-top: .4rem;
    }
  }

  .video-fade-container {
    transition: linear;
    background-color: black;

    &.show {
      opacity: 1;
    }

    &.hide {
      opacity: 0;
    }
  }

  .NavBackButton {
    margin-bottom: 4rem;
  }

  .legend-svg {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
}
