.IntroductionPage {
  overflow: hidden;
  position: relative;

  .about-us-container {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1rem 3rem;
    pointer-events: none;
    transform: translateX(calc(-100% - 3rem));
    transition: ease-in-out .5s;
    font-family: Merriweather, serif;

    .about-us {
      font-family: Merriweather, serif;
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: .3rem;
      padding-left: .3rem;
      opacity: .8;
    }

    &.show {
      pointer-events: all;
      transform: translateX(0);
    }

    h2 {
      border-bottom: 2px transparent solid;

      &:hover {
        border-bottom: 2px $clrHighlight solid;
        opacity: 1;
      }
    }
  }

  .page-headline {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .slides-container {
    position: relative;

    .slide {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      transition: opacity 0.5s ease-in-out;
      opacity: 0;


      .background-image {
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
      }

      &.isActive {
        opacity: 1;
      }

      &:not(.isActive) {
        pointer-events: none;
      }
    }
  }

  .slide-navigation {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 1rem 4rem 1rem 1rem;

    .RoundButton {
      margin: 1rem;
    }
  }

  .slide {
    .bottom-element {
      position: absolute;
      vertical-align: center;
      bottom: 10vh;
      font-size: 1.2rem;
    }

    &.SlideLife {
      .quiz-reputation {
        transform: translate(-200px, -320px);
      }

      .quiz-history {
        transform: translate(320px, -310px);
      }

      .quiz-sport {
        transform: translate(-660px, 40px);
      }

      &.isActive {
        .quiz-reputation, .quiz-history, .quiz-sport {
          opacity: 0;
        }

        .quiz-sport {
          animation: fade-in 1s ease-in 5s forwards;
        }

        .quiz-history {
          animation: fade-in 1s ease-in 6s forwards;
        }

        .quiz-reputation {
          animation: fade-in 1s ease-in 7s forwards;
        }
      }
    }

    &.SlideWork {


      .quiz-companies {
        transform: translate(-560px, -90px);
      }

      .quiz-jobs {
        transform: translate(220px, -150px);
      }

      .bottom-element {
        &.text-container, &.go-to-main-button {
          opacity: 0;
        }
      }

      &.isActive {
        .quiz-companies, .quiz-jobs {
          opacity: 0;
        }

        .quiz-companies {
          animation: fade-in 1s ease-in 4s forwards;
        }

        .quiz-jobs {
          animation: fade-in 1s ease-in 5s forwards;
        }

        .bottom-element {
          &.text-container, &.go-to-main-button {
            opacity: 0;
          }
        }

        .bottom-element {
          &.text-container {
            opacity: 1;
            animation: move-up 1s ease-out 5s forwards;

            @keyframes move-up {
              to {
                transform: translateY(-2.5rem);
              }
            }
          }

          &.go-to-main-button {
            text-transform: uppercase;
            opacity: 0;
            padding: 0.5rem 3.5rem;
            animation: fade-in 1s ease-in 6s forwards;
            transform: translateY(1.5rem);
            animation-iteration-count: 1, infinite;

            p {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }

  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
