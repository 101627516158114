.LocationDescription {
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: left;
  margin: 0 0 2rem 4rem;

  .horizontal-container {
    text-align: center;
    padding-top: .5rem;
    align-items: flex-end;

    & > :not(:last-child) {
      margin-right: 1.5rem;
    }

    .icon-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      img {
        max-width: 36px;
        padding-bottom: .4rem;
      }
    }
  }
}
