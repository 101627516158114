.EntryLabel {
  $line-height: $lineThin;
  position: relative;

  h3 {
    padding-bottom: .3rem;
    font-weight: 600;
    margin: unset;
    border-bottom: $line-height solid white;
  }

  .label-line {
    position: absolute;
    border-bottom: $line-height solid white;
  }

  &.right {
    .label-line {
      right: 0;
    }
  }
}
