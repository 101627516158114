.VideoPage {

  .background-image {
    position: absolute;
    background-position: center;
    background-size: cover;
  }

  .timeline-container {
    transition: .3s ease-in;

    .TimeLine {
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 0 4rem 2rem 0;
    }
  }
}

