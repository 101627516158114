.SlideInContainer {
  text-align: left;

  .image-container {
    width: 100%;
    height: 400px;
    object-position: center center;
    margin-left: 2rem;

    img {
      float: left;
      object-fit: cover;
      width: 100%;
      height: 100%;
      filter: brightness(.8);
    }
  }

  .location-container {
    display: flex;
    align-items: flex-start;
    margin-top: -2rem;
  }

  .description-container {
    margin-left: 2rem;
  }

  .facts-container {
    transform: translateY(-4rem);
  }
}
