.ProgressIndicator {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  &.glow {
    transition: opacity 1s linear;
    opacity: .8;
    animation: glow-strong ease-in-out 1.5s 1s alternate infinite;
  }

  svg {
    width: 4rem;
    height: 4rem;
    pointer-events: none;

    &.hide path {
      opacity: 0;
    }

    path {
      stroke: white;
      stroke-width: 2px;
      animation: draw-strokes linear forwards;
    }
  }

  .icon {
    position: absolute;
    width: 12px;
  }

  @keyframes draw-strokes {
    to {
      stroke-dashoffset: 0;
    }
  }
}
